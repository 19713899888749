import React, { useEffect, useState } from "react";
import styles from "./HeaderSection.module.css";
import mac from '../../assets/header/macbook-pro.png'
import iPad from '../../assets/header/ipad.png'
import lang from '../../lang.json'

const HeaderSection = () => {
    let [parsedSubtitle, setParsedSubtitle] = useState('')
    const parser = new DOMParser();
    const langValue = localStorage.getItem('lang');
    
    useEffect(() => {
        if (langValue === 'RU') {
            const subtitleHtml = `<span class=${styles.bold_text}>Firstsoft</span> - это <span class=${styles.bold_text}>официальный партнер фирмы «1С»,</span> который поможет вам правильно вести бизнес и быть на рынке профессионалом`;
            const parsedSubtitle = parser.parseFromString(subtitleHtml, 'text/html');
            const extractedHtml = parsedSubtitle.body.innerHTML;
            setParsedSubtitle(extractedHtml);
        } else {
            // Если не RU, используем текст из lang.json
            setParsedSubtitle(lang.home.header.subtitle);
        }
    }, [])

    function animated_text_content(clasi = ''){
        let content_text = [];
        for(let i = 0; i < 5; i++){
            content_text.push(

                <div key={i} className={`${styles.text_scroll} ${styles['text' + i + clasi]}`}>
                    <div>
                        {
                            localStorage.getItem('lang') === 'RU' ? '1С:Управление торговлей 8 1С:Бухгалтерия 8 1С:Управление нашейфирмой 1С:Зарплата и управлениеперсоналом 8 С:ERP Управление предприятием1С:Розница 1С:Касса 1С:Договоры 1С:Документооборот 1С:Комплексная автоматизация' : lang.home.header.bg_text
                        }
                    </div>
                    <div>
                        {
                            localStorage.getItem('lang') === 'RU' ? '1С:Управление торговлей 8 1С:Бухгалтерия 8 1С:Управление нашейфирмой 1С:Зарплата и управлениеперсоналом 8 С:ERP Управление предприятием1С:Розница 1С:Касса 1С:Договоры 1С:Документооборот 1С:Комплексная автоматизация' : lang.home.header.bg_text
                        }
                    </div>
                </div>
            )
        }
        return(content_text)
    }
    return(
        <div className={styles.header}>
            <section className={styles.head_section}>
                <div className={styles.head_title}>
                    <h1>{
                        localStorage.getItem('lang') === "RU" ? 'Программные решения и интеграции для вашего бизнеса' : 'Biznes üçün proqram həlləri və inteqrasiyalar'
                        }</h1>
                    <p>
                        <span dangerouslySetInnerHTML={{__html: parsedSubtitle}}/>                       
                    </p>
                    <button onClick={() => document.getElementById('contact').scrollIntoView()}>{
                        localStorage.getItem('lang') === "RU" ? 'Связаться с нами' : lang.home.header.btn
                        }</button>
                </div>
                <div className={styles.head_title_presentation}>
                    <div className={styles.images_block}>
                        <img className={styles.mac_image} src={mac}/> 
                        <img className={styles.pad_image} src={iPad}/> 
                    </div>
                </div>
            </section>
            <div className={styles.sub_section_header}>
                <p className="">
                    {
                        langValue === 'RU' ? parser.parseFromString("Предоставляем полный спектр услуг для автоматизации бизнес-процессов компании посредством решений на базе 1С:Предприятие", 'text/html').body.innerHTML : parser.parseFromString(lang.home.header.sub_header, 'text/html').body.innerHTML
                    }
                </p>
            </div>
            <div className={styles.presentation_text}>
                {animated_text_content()}
                {animated_text_content('x')}
            </div>
        </div>
    )
}

export default HeaderSection;