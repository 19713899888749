import React from "react";

import styles from "./Predpriyatie.module.css"



const Predpriyatie = () => {
    const windowses = [
        <div>
            <div className={styles.left_side_header}>
                <div className={styles.left_side_title}>
                    {localStorage.getItem('lang')  === 'RU' ? <h1>Что такое <img className={styles.title_logo} src={require('../../../assets/crm/1C_logo.png')} />?</h1> : <h1><img className={styles.title_logo} src={require('../../../assets/crm/1C_logo.png')} />nədir?</h1>}
                </div>
                {localStorage.getItem('lang') === 'RU' ? <p> — семейство программных продуктов для автоматизации учета и бизнес-процессов. Первая выпущенная программа 1С была «1С Бухгалтерия». Сегодня же в арсенале фирмы «1С» и её партнеров более 500 различных решений для разных задач и различных отраслей.</p> : <p> — 1C proqram təminatlarının və iş proseslərinin avtomatlaşdırılması üçün proqramlar ailəsidir. İlk buraxılan 1C proqramı "1C Mühasibat" idi. Ancaq bugün "1C" şirkəti və onun tərəfdaşları üçün müxtəlif vəzifələr və sahələr üçün 500-dən çox müxtəlif həlllərə sahibdir</p>}
                <button><a href="https://v8.1c.ru/ka/#:~:text=%C2%AB1%D0%A1%3A%D0%9A%D0%BE%D0%BC%D0%BF%D0%BB%D0%B5%D0%BA%D1%81%D0%BD%D0%B0%D1%8F%20%D0%B0%D0%B2%D1%82%D0%BE%D0%BC%D0%B0%D1%82%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D1%8F%C2%BB%20%E2%80%94%20%D1%8D%D1%82%D0%BE%20%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0%20%D0%B4%D0%BB%D1%8F%20%D0%BF%D0%BE%D1%81%D1%82%D1%80%D0%BE%D0%B5%D0%BD%D0%B8%D1%8F%20%D0%BD%D0%B0,%2C%20%D1%80%D0%B0%D1%81%D1%87%D0%B5%D1%82%20%D0%B7%D0%B0%D1%80%D0%BF%D0%BB%D0%B0%D1%82%D1%8B%2C%20%D0%BA%D0%B0%D0%B4%D1%80%D0%BE%D0%B2%D1%8B%D0%B9%20%D1%83%D1%87%D0%B5%D1%82." target="_blank">ПОДРОБНЕЕ</a></button>
            </div>
            <div className={styles.right_side_title}>
                <div className={styles.right_side_imgs}>
                    <img src={require('../../../assets/crm/1c.png')}/>
                </div>
            </div>
        </div>, 
        <div>
            <div className={styles.left_side_header}>
                    <div className={styles.left_side_title}>
                        <h1><img className={styles.title_logo} src={require('../../../assets/crm/1C_logo.png')} /> Предприятие</h1>
                    </div>
                    {localStorage.getItem('lang') === 'RU' ? <p> 1С Предприятие — это также название технологической платформы, на которой разрабатываются все прикладные решения. Сегодня актуальная версия платформы 1С — 8.3. Однако многие предприятия работают на 1С 8.2, 8.1 и даже 7.7.</p> : <p>1C İş — bu, bütün tətbiq həllərin hazırlandığı texnologiya platformasının adıdır. Hazırda 1C platformasının aktual versiyası 8.3-dür. Lakin bir çox müəssisələr 1C 8.2, 8.1 və hətta 7.7 versiyalarında işləyirlər.</p>}
                    <button>{localStorage.getItem('lang') === 'RU' ? 'ПОДРОБНЕЕ' : 'ƏTRAFLI'}</button>
                </div>
                <div className={styles.right_side_title}>
                    <div className={styles.right_side_imgs}>
                        <img src={require('../../../assets/crm/1c.png')}/>
                    </div>
                </div>
        </div>
    ]

    
    return (
        <header className={`${styles.header} ${styles.c_header}`}>
            <div className={styles.area} >
                <ul className={styles.circles}>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                </ul>
            </div >
            <div className={styles.left_side_header}>
                <div className={styles.left_side_title}>
                {localStorage.getItem('lang')  === 'RU' ? <h1>Что такое <img className={styles.title_logo} src={require('../../../assets/crm/1C_logo.png')} />?</h1> : <h1><img className={styles.title_logo} src={require('../../../assets/crm/1C_logo.png')} /> nədir?</h1>}
                </div>
                <p>{
                    localStorage.getItem('lang') === 'RU' ?  '— семейство программных продуктов для автоматизации учета и бизнес-процессов. Первая выпущенная программа 1С была «1С Бухгалтерия». Сегодня же в арсенале фирмы «1С» и её партнеров более 500 различных решений для разных задач и различных отраслей.' : ' — mühasibat və biznes proseslərinin avtomatlaşdırılması üçün proqram məhsulları ailəsi. İlk yayımlanan 1C proqramı "1C Mühasibat" idi. Bu gün "1C" şirkətinin və tərəfdaşlarının arsenalında müxtəlif vəzifələr və müxtəlif sahələr üçün 500-dən çox fərqli həll yolu var.'
                    }</p>
                <button>{localStorage.getItem('lang') === 'RU' ? 'ПОДРОБНЕЕ' : 'Daha ətraflı'}</button>
            </div>
            <div className={styles.right_side_title}>
                <div className={styles.right_side_imgs}>
                    <img src={require('../../../assets/crm/1c.png')}/>
                </div>
            </div>
        </header>
    )
}

export default Predpriyatie;