import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./partners.module.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";


// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

import p1_c from "../../assets/partners/1c.png"
import pAdobe from "../../assets/partners/Adobe.png"
import pAgent from "../../assets/partners/agent.png"
import pBtrx from "../../assets/partners/Bitrix24.png"
import pKaspersky from "../../assets/partners/Kaspersky.png"
import pMicrosoft from "../../assets/partners/Microsoft.png"
import pMobi from "../../assets/partners/mobi.png"

import cUnimetal from "../../assets/customers/unimetal.png"
import cPAcademy from "../../assets/customers/polis_akademiyasi.png"
import cLocitane from "../../assets/customers/loccitane.png"
import cOMarines from "../../assets/customers/original_marines.png"
import cAroc from "../../assets/customers/aroc_mmc.webp"
import cCkale from "../../assets/customers/canakkale.png"
import cEClima from "../../assets/customers/eoruclima.png"
import cGrow from "../../assets/customers/grow_group.jpg"
import cETech from "../../assets/customers/euro_tech.png"
import cMService from "../../assets/customers/master_service.png"
import cVProtection from "../../assets/customers/vostok_protection.jpeg"
import cFS from "../../assets/customers/fs-clubs-network.png"
import cSStore from "../../assets/customers/shop_store.webp"
import cVGroup from "../../assets/customers/Vien_group.jpg"
import cNMedia from "../../assets/customers/New_media.jpg"
import cMcCann from "../../assets/customers/mccann_erickson.png"
import cHavas from "../../assets/customers/havas.png"
import cWTelecom from "../../assets/customers/west_telecom.webp"
import cBRPG from "../../assets/customers/brpg.png"
import cBDistr from "../../assets/customers/baku_distribution.png"

const Partners = () => {
  const partners = [p1_c, pAdobe, pAgent, pBtrx, pKaspersky, pMicrosoft, pMobi],
        customers = [[cUnimetal, cLocitane, cOMarines, cPAcademy,cVProtection], [cAroc, cCkale, cEClima, cETech, cNMedia], [cSStore, cFS, cVGroup, cMcCann, cGrow], [cHavas, cWTelecom, cBRPG, cBDistr, cMService]]
  return (
    <div>
      <div className={styles.partners_block}>
        <h1>
          {
              localStorage.getItem('lang') === 'RU' ? 'Наши партнёры' : 'Partnyorlarımız'
          }
          </h1>
        <div className={styles.partners}>
          {partners.map((e, i) => {
            return(
              <div className={styles.partners_item} key={i}>
                <img src={e}/>
              </div>
            )
          })}
        </div>
      </div>
      <h1>
          {
              localStorage.getItem('lang') === 'RU' ? 'С нами работают' : 'Bizlə işləyənlər'
          }</h1>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
          reverseDirection:true
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className={styles.mySwiper}
      >
        {
          customers.map((e, i) => {
            return(
                <SwiperSlide className={styles.customers_container} key={i}>
                  {
                    e.map((e2, i2) => {
                      return(
                        <div className={styles.customer_logo}>
                          <img key={i2} src={e2}/>
                        </div>
                      )
                    })
                  }
                </SwiperSlide>
            )
          })
        }
      </Swiper>
    </div>
  );
}

export default Partners;