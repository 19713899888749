import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import rustdesk from "../../assets/rustdesk/rustdesk-host=65.21.54.98,key=b7XYLQmIOLNKdk1KH0iLJzz7caDsq8YNbq+emOEnxDw=.exe";

import styles from "./Nav.module.css";
import logo from '../../assets/logo.png';
import text_logo from '../../assets/logotext.png';
import langj from '../../lang.json'
import { BarsOutlined } from '@ant-design/icons';
import { Drawer } from "antd";

const Nav = () => {
        let [lang, setLang] = useState(localStorage.getItem('lang'))
        const parser = new DOMParser();
        const langValue = localStorage.getItem('lang');
        const [openMenu, setOpenMenu] = useState(false)

        const handleMenu = () => {
            setOpenMenu(true)
        }

        useEffect(() => {
            localStorage.setItem('lang', lang)
        }, [lang])
        return(
            <>
                <div className={styles.mob_menu}>
                    <header className={styles.mob_header}>
                        <div className={styles.mob_right}>
                            <select onChange={(e) => {
                                setLang(e.target.value)
                                window.location.reload()
                            }} value={lang} className={styles.lang_select}>
                                <option value='RU'>
                                    RU
                                </option>
                                <option value='AZ'>
                                    AZ
                                </option>
                            </select>
                            <Drawer width={'270px'} className={styles.mob_driwer} title="Menu" placement="right" onClose={() => {setOpenMenu(false)}} open={openMenu}>
                                <nav className={styles.nav_bar_mob}>
                                    <ul>
                                        <li onClick={() => {setOpenMenu(false)}}><NavLink to="/1c" className={styles.link}>1C</NavLink></li>
                                        <li onClick={() => {setOpenMenu(false)}}><NavLink to="/bitrix24" className={styles.link}>Bitrix</NavLink></li>
                                        <li onClick={() => {setOpenMenu(false)}}><NavLink to="/services" className={styles.link}>{langValue ==='RU' ? 'Продукты' : langj.nav.nav3}</NavLink></li>
                                        <li><a style={{color: 'red'}} href={rustdesk} download="rustdesk-host=65.21.54.98,key=b7XYLQmIOLNKdk1KH0iLJzz7caDsq8YNbq+emOEnxDw=.exe" className={styles.link}>{langValue ==='RU' ? 'Скачать RustDesk' : 'RustDesk yüklə'}</a></li>
                                    </ul>
                                </nav>
                                <ul className={styles.drawer_mob_footer}>
                                    <li><a href="tel:+994512069476">+994 (51) 206 94 76</a></li>
                                    <li><a href="mailto:info@firstsoft.az">info@firstsoft.az</a></li>
                                </ul>    
                            </Drawer>
                            <BarsOutlined className={styles.menu_mob} onClick={handleMenu}/>
                        </div>
                        <div className={styles.logo_block}>
                            <span>
                                <NavLink to='/'><img src={logo} alt=""/></NavLink>
                            </span>
                        </div>
                    </header>
                </div>
                <header className={styles.header} id="navigation">
                    <div className={styles.logo_block}>
                        <span>
                            <NavLink to='/'><img src={logo} alt=""/></NavLink>
                        </span>
                        <h3><Link to='/'><img src={text_logo} alt=""/></Link></h3>
                    </div>
                    <nav className={styles.nav_bar}>
                        <ul>
                            <li><NavLink to="/1c" className={styles.link}>1C</NavLink></li>
                            <li><NavLink to="/bitrix24" className={styles.link}>Bitrix</NavLink></li>
                            <li><NavLink to="/services" className={styles.link}>{langValue ==='RU' ? 'Продукты' : langj.nav.nav3}</NavLink></li>
                            <li><a style={{color: 'red'}} href={rustdesk} download="rustdesk-host=65.21.54.98,key=b7XYLQmIOLNKdk1KH0iLJzz7caDsq8YNbq+emOEnxDw=.exe" className={styles.link}>{langValue ==='RU' ? 'Скачать RustDesk' : 'RustDesk yüklə'}</a></li>
                        </ul>
                    </nav>
                    <ul className={styles.side_links}>
                        <li><a href="#contact">{langValue ==='RU' ? 'Контакты' : langj.nav.nav4}</a></li>
                        <li>
                            <select onChange={(e) => {
                                setLang(e.target.value)
                                window.location.reload()
                            }} value={lang} className={styles.lang_select}>
                                <option value='RU'>
                                    RU
                                </option>
                                <option value='AZ'>
                                    AZ
                                </option>
                            </select>
                        </li>
                    </ul>     
                    
                </header>
            </>
            
        )}


export default Nav;