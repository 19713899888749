import React from "react";
import styles from './Header.module.css';

const Header = () => {
    const lang = localStorage.getItem('lang')
    let squares = () => {
        let body = [];
        for(let i = 0; i < 11; i++){
            body.push(<div></div>)
        }
        return body
    }
    return(
        <header className={styles.header}>
            <div className={styles.floating_squares}>
                {squares()}
            </div>
            <div className={styles.left_side_header}>
                
                    {lang === 'RU' ? <div className={styles.left_side_title} style={{flexDirection: 'row'}}>
                    <h1 style={{padding: 0}}>Что такое</h1> <img src={require('../../../assets/crm/bitrix-logo.png')}/> 
                         </div>: 
                         <div className={styles.left_side_title} style={{flexDirection: 'row'}}><img src={require('../../../assets/crm/bitrix-logo.png')}/><h1 style={{padding: 0, marginLeft: '15px'}}> nədir?</h1></div>
                         }
                    
                <p>{lang === 'RU' ? 'Битрикс24 – это набор полезных инструментов, которые помогают бизнесу работать: CRM, Видеозвонки, Документы Онлайн, Задачи и проекты, Контакт-центр, конструктор сайтов и Интернет-магазины.' : 'Bitrix24, işin işləməsinə kömək edən faydalı vasitələr toplusudur: CRM, video zənglər, onlayn sənədlər, tapşırıqlar və Layihələr, əlaqə mərkəzi, veb sayt qurucusu və onlayn mağazalar.'}</p>
                <button>{lang === 'RU' ? 'ПОДРОБНЕЕ' : 'Daha ətraflı'}</button>
            </div>
            <div className={styles.right_side_title}>
                <div className={styles.right_side_imgs}>
                    <img src={require('../../../assets/crm-section/header.png')}/>
                    {/* <img src={require('../../../assets/crm/iphone-layer1.png')}/> */}
                </div>
            </div>
        </header>
    )
}

export default Header;