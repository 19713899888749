import React from "react";
import Header from "./Header/Header";
import Section from "./Sections/Section";

import styles from "./Services.module.css";
import data from "../../data.json";
import dataAz from "../../dataAz.json";
import PriceList from "./PriceList/PriceList";
import Serve from "./Serve/Serve";


const Services = () => {
    let lang = localStorage.getItem('lang')
    if(lang === 'RU'){
        return(
            <section>
                <Header/>
                {
                    data.services.map((e) => {
                        return(
                            <Section {...e}/>
                        )
                    })
                }
                <PriceList/>
                {/* <Serve/> */}
            </section>
        )
    }else{
        return(
            <section>
                <Header/>
                {
                    dataAz.services.map((e) => {
                        return(
                            <Section {...e}/>
                        )
                    })
                }
                <PriceList/>
                {/* <Serve/> */}
            </section>
        )
    }
}

export default Services;