import React from "react";
import styles from "./Solutions.module.css";

const Solutions = () => {
    const lang = localStorage.getItem('lang')
    return(
        <div className={styles.solutions}>
            <h1 className={styles.solution_title}>
                {
                    lang === 'RU' ? 'Популярные решения компании «1С»' : '«1С» şirkətinin məşhur həlləri'
                }
            </h1>
            <div className={styles.img_carousel}>
                <div className={styles.image_list}>
                    {images.map(((el, i) => <img key={i} src={require('../../assets/solutions/' + el + '.png')} alt=''/>))}
                    {images.map(((el, i) => <img key={i+1} src={require('../../assets/solutions/' + el + '.png')} alt=''/>))}
                </div>
            </div>
            <button onClick={() => document.getElementById('contact').scrollIntoView()} className={styles.button}>{lang === 'RU' ? 'Заказать' : 'Sifariş et'}</button>
        </div>
    )
}

const images = ['avtom', 'buhgal', 'dogovor', 'erp', 'kassa', 'oborot', 'roznitsa', 'torgovlya', 'upravlenie', 'zp']

export default Solutions;