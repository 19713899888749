import React from "react";
import CrmSection from "./CrmSections/CrmSection";
import Header from "./Header/Header";
import data from '../../data.json';
import dataAz from '../../dataAz.json';

const Crm = () => {
    let lang = localStorage.getItem('lang')
    if(lang == "RU"){
        return(
            <div>
                <Header/>
                {
                    data.crm_sections.map(el => {
                        return <CrmSection key={el.id} {...el}/>
                    })
                }
            </div>
        )
    }else{
        return(
            <div>
                <Header/>
                {
                    dataAz.crm_sections.map(el => {
                        return <CrmSection key={el.id} {...el}/>
                    })
                }
            </div>
        )
    }
}

export default Crm;