import React, { useRef } from "react";
import emailjs from '@emailjs/browser'

import styles from "./Contact.module.css";

const Contacts = () => {
    const form = useRef();
    const lang = localStorage.getItem('lang')
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_7q01xn7', 'template_i9gtc3b', form.current, 'HQ6451BoYq09vFiOj')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          e.target.reset()
      };
    return(
        <section id="contact" className={styles.contacts}>
            <div className={styles.contacts_block}>
                <div className={styles.frame_block}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d759.4047675613683!2d49.857381942320885!3d40.417288846513905!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x403087fdbc9753fb%3A0x1f116eda50acb1ce!2zQ1Y5NCsyUjUsIEJha8Sx!5e0!3m2!1sru!2saz!4v1694429841099!5m2!1sru!2saz" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"/>
                </div>
                <div className={styles.form_block}>
                    <form ref={form} onSubmit={sendEmail}>
                        <div className={styles.form_title}>
                            <p>{lang === 'RU' ? 'Мы свяжемся с вами, оставьте заявку' : 'Sizinlə əlaqə saxlayacağıq, bir sorğu buraxın'}</p>
                        </div>
                        <div className={styles.form_inputs}>
                            <input name="user_name"type="text" placeholder={lang === 'RU' ? "Ваше имя" : 'Adınız'} autoComplete="off" required/>
                            <input name="user_phone" type="number" placeholder={lang === 'RU' ? "Номер телефона" : 'Telefon nömrəniz'} autoComplete="off" required/>
                            <input name="user_email" type="email" placeholder={lang === 'RU' ?  "Электронная почта" : 'E-poçt'} autoComplete="off" required/>
                            <input name="message" type="text" placeholder={lang === 'RU' ? "Какие услуги вас интересуют?" : 'Hansı Xidmətlər Sizi maraqlandırır?'} autoComplete="off" required/>
                            <input name="business" type="text" placeholder={lang === 'RU' ? "Ваш бизнес" : 'Sizin biznes'} autoComplete="off" required/>
                            <button type="submit">{lang === 'RU' ? 'Оставить заявку' : 'Sorğu buraxın'}</button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    )
}

export default Contacts;