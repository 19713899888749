import React from "react";

import logo from "../../assets/footer_logo.png"
import styles from './Footer.module.css';
import { Link } from "react-router-dom";

const Footer = () => {
    const lang = localStorage.getItem('lang')

    return(
        <footer>
            <img className={styles.bg_img} src={require('../../assets/web-logo.png')}/>
            <div className={styles.behind_block}>
                <div className={styles.footer_order}>
                    <div>
                        <h2>{lang === 'RU' ? 'Готовы автоматизировать бизнес?' : 'İşi avtomatlaşdırmağa hazırsınız?'}</h2>
                    </div>
                    <button onClick={() => document.getElementById('contact').scrollIntoView()}>{lang === 'RU' ? 'Связаться с нами' : 'Bizimlə əlaqə saxlayın'}</button>
                </div>
            </div>
            <div className={styles.footer_list}>
                <div className={styles.footer_left_side}>
                    <img src={logo}/>
                    <p>{lang === 'RU' ? 'Мы меняем рынок!' : 'Bazarı dəyişdiririk!'}</p>
                </div>
                <div className={styles.footer_right_side}>
                    <ul>
                        <li><Link to={'/'}>{lang === 'RU' ? 'Главная' : 'Əsas səhifə'}</Link></li>
                        <li><a href="tel:+994512069476">+994 (51) 206 94 76</a></li>
                        <li><a href="mailto:info@firstsoft.az">info@firstsoft.az</a></li>
                        {/* <li>{lang === 'RU' ? 'С нами сотрудничают' : 'Bizimlə əməkdaşlıq edirlər'}</li> */}
                    </ul>
                </div>
            </div>
            <div className={styles.footer_copyright}>
                <h3>{lang === 'RU' ? '©2022 Все права защищены' : '©2022 Bütün hüquqlar qorunur'}</h3>
            </div>
        </footer>
    )
}

export default Footer;