import React from "react";
import styles from './MainC.module.css';

import Section from "./Sections/Section";
import Predpriyatie from "./Predpriyatie/Predpriyatie";
import data from '../../data.json';
import dataAz from "../../dataAz.json"


const MainC = () => {
    const lang = localStorage.getItem('lang')
    if(lang == "RU"){
        return(
            <div className={styles.containerc}>
                <Predpriyatie/>
                {
                    data.predpriyatie.map(el => {
                        return <Section key={el.id} {...el}/>
                    })
                }
            </div>
        )
    }else{
        return(
            <div className={styles.container}>
                <Predpriyatie/>
                {
                    dataAz.predpriyatie.map(el => {
                        return <Section key={el.id} {...el}/>
                    })
                }
            </div>
        )
    }
}

export default MainC;