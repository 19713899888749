import React from "react";

import BtrxSection from "../BtrxSection/BtrxSection";
import HeaderSection from "../HeaderSection/HeaderSection";
import Section from '../Sections/Section';
import data from '../../data.json'
import dataAz from "../../dataAz.json"
import Solutions from "../Solutions/Solutions";
import FooterSection from "../FooterSection/FooterSection";
import Partners from "../partners/partners";


const HomePage = () => {
    const lang = localStorage.getItem('lang')

    if(lang == "RU"){
        return <>
            <HeaderSection/>
            {
                data.sections.map(el => {
                    return(
                        <Section key={el.id} {...el}/>
                    )
                })
            }
            <Partners/>
            {
                data.footerSections.map(el => {
                    return(
                        <FooterSection key={el.id} {...el}/>
                    )
                })
            }
            <Solutions/>
            <BtrxSection/>
        </>
    }else{
        return <>
            <HeaderSection/>
            {
                dataAz.sections.map(el => {
                    return(
                        <Section key={el.id} {...el}/>
                    )
                })
            }
            <Partners/>
            {
                dataAz.footerSections.map(el => {
                    return(
                        <FooterSection key={el.id} {...el}/>
                    )
                })
            }
            <Solutions/>
            <BtrxSection/>
        </>
    }
}

export default HomePage;