import React from "react";

import styles from "./Header.module.css";

const lang = localStorage.getItem('lang')

const Header = () => <header className={styles.header}>
    <div className={styles.left_side_header}>
        <div className={styles.left_side_title}>
            {lang === 'RU' ? <h1>{lang === 'RU' ? 'Мы поможем вам выбрать правильный продукт для вашего' : 'İşiniz üçün doğru məhsulu seçməyinizə kömək edəcəyik'} <span>{lang === 'RU' ? 'Бизнеса' : ''}</span>!</h1> : 
            <h1><span>İşiniz</span> üçün doğru məhsulu seçməyinizə kömək edəcəyik!</h1>
            }
        </div>
        <p>{lang === 'RU' ? 'Если у вас небольшая фирма или вы управляете крупным холдингом, вы всегда сможете найти для себя самое оптимальное решение из линейки фирмы «1С».' : 'Kiçik bir şirkətiniz varsa və ya böyük bir holdinq işlədirsinizsə, həmişə "1C"şirkətinin xəttindən özünüz üçün ən optimal həll yolu tapa bilərsiniz.'}</p>
    </div>
    <div className={styles.right_side_title}>
        <div className={styles.right_side_imgs}>
            <img src={require('../../../assets/Services/header.png')}/>
        </div>
    </div>
</header>

export default Header;